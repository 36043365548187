import {
    // GET_BUSINESS_REPORT_GENERAL_STARTED,
    // GET_BUSINESS_REPORT_GENERAL_SUCCESS,
    // GET_BUSINESS_REPORT_GENERAL_FAILURE,
    // GET_BUSINESS_REPORT_MONTHS_STARTED,
    // GET_BUSINESS_REPORT_MONTHS_SUCCESS,
    // GET_BUSINESS_REPORT_MONTHS_FAILURE,
    GET_BUSINESS_PAYMENT_LIST_STARTED,
    GET_BUSINESS_PAYMENT_LIST_SUCCESS,
    GET_BUSINESS_PAYMENT_LIST_FAILURE,
    CREATE_BUSINESS_PAYMENT_INTENT_STARTED,
    CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS,
    CREATE_BUSINESS_PAYMENT_INTENT_FAILURE,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS,
    CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE,
    GET_BUSINESS_PAYMENT_DETAIL_STARTED,
    GET_BUSINESS_PAYMENT_DETAIL_SUCCESS,
    GET_BUSINESS_PAYMENT_DETAIL_FAILURE,
    GET_BUSINESS_PAYMENT_PROFILE_STARTED,
    GET_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    GET_BUSINESS_PAYMENT_PROFILE_FAILURE,
    CREATE_BUSINESS_PAYMENT_PROFILE_STARTED,
    CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED,
    UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    DELETE_PAYMENT_STARTED,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAILURE,
    REFUND_PAYMENT_STARTED,
    REFUND_PAYMENT_SUCCESS,
    REFUND_PAYMENT_FAILURE,
    SEND_PAYMENT_DECLINE_REMINDER_STARTED,
    SEND_PAYMENT_DECLINE_REMINDER_SUCCESS,
    SEND_PAYMENT_DECLINE_REMINDER_FAILURE,
    GET_COMPLETED_APPOINTMENTS_STARTED,
    GET_COMPLETED_APPOINTMENTS_SUCCESS,
    GET_COMPLETED_APPOINTMENTS_FAILURE,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    GET_BUSINESS_APPOINTMENT_COUNT_STARTED,
    GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS,
    GET_BUSINESS_APPOINTMENT_COUNT_FAILURE,
    CREATE_BUSINESS_PAYMENT_STARTED,
    CREATE_BUSINESS_PAYMENT_SUCCESS,
    CREATE_BUSINESS_PAYMENT_FAILURE,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/business-payment/business-payment";
import API from "../../../utils/API/API";
import { LOGOUT } from "../../constants/employee-account/login";


export const createBusinessPayment = (services, taxes, business, description) => {
    return (dispatch) => {
        dispatch(createBusinessPaymentStarted())
        API.post(`/api/businesses/payments/create-payment`, { services, taxes, business, description })
            .then((res) => {
                dispatch(createBusinessPaymentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, createBusinessPaymentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const createBusinessPaymentStarted = () => ({
    type: CREATE_BUSINESS_PAYMENT_STARTED,
});

const createBusinessPaymentSuccess = (data) => ({
    type: CREATE_BUSINESS_PAYMENT_SUCCESS,
    payload: data
});

const createBusinessPaymentFailure = (error) => ({
    type: CREATE_BUSINESS_PAYMENT_FAILURE,
    payload: { error },
});


export const countCompletedAppointment = (business, to, from) => {
    return (dispatch) => {
        dispatch(countCompletedAppointmentStarted())
        API.post(`/api/businesses/payment/count-total-appointment`, { business, to, from })
            .then((res) => {
                dispatch(countCompletedAppointmentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, countCompletedAppointmentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const countCompletedAppointmentStarted = () => ({
    type: GET_BUSINESS_APPOINTMENT_COUNT_STARTED,
});

const countCompletedAppointmentSuccess = (data) => ({
    type: GET_BUSINESS_APPOINTMENT_COUNT_SUCCESS,
    payload: data
});

const countCompletedAppointmentFailure = (error) => ({
    type: GET_BUSINESS_APPOINTMENT_COUNT_FAILURE,
    payload: { error },
});


export const deletePayment = (paymentId) => {
    return (dispatch) => {
        dispatch(deletePaymentStarted())
        API.delete(`/api/businesses/payments/delete?paymentId=${paymentId}`)
            .then((res) => {
                dispatch(deletePaymentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, deletePaymentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const deletePaymentStarted = () => ({
    type: DELETE_PAYMENT_STARTED,
});

const deletePaymentSuccess = (data) => ({
    type: DELETE_PAYMENT_SUCCESS,
    payload: data.payment
});

const deletePaymentFailure = (error) => ({
    type: DELETE_PAYMENT_FAILURE,
    payload: { error },
});


// export const getBusinessReportMonths = (business) => {
//     return (dispatch) => {
//         dispatch(getBusinessReportMonthsStarted())
//         API.get(`/api/businesses/statistics/get-list?id=${business}`)
//             .then((res) => {
//                 dispatch(getBusinessReportMonthsSuccess(res.data));
//             }).catch((err) => {
//                 catchError(err, dispatch, getBusinessReportMonthsFailure)
//             }).finally(() => setTimeout(function () {
//                 dispatch(removeErrorMessage());
//             }, 3000))
//     }
// };

// const getBusinessReportMonthsStarted = () => ({
//     type: GET_BUSINESS_REPORT_MONTHS_STARTED,
// });

// const getBusinessReportMonthsSuccess = (data) => ({
//     type: GET_BUSINESS_REPORT_MONTHS_SUCCESS,
//     payload: { data },
// });

// const getBusinessReportMonthsFailure = (error) => ({
//     type: GET_BUSINESS_REPORT_MONTHS_FAILURE,
//     payload: { error },
// });

export const sendPaymentDeclineReminder = (business) => {
    return (dispatch) => {
        dispatch(sendPaymentDeclineReminderStarted())
        API.post(`/api/businesses/payments/payment-declined-reminder`, { business })
            .then((res) => {
                dispatch(sendPaymentDeclineReminderSuccess());
            }).catch((err) => {
                catchError(err, dispatch, sendPaymentDeclineReminderFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const sendPaymentDeclineReminderStarted = () => ({
    type: SEND_PAYMENT_DECLINE_REMINDER_STARTED,
});

const sendPaymentDeclineReminderSuccess = () => ({
    type: SEND_PAYMENT_DECLINE_REMINDER_SUCCESS
});

const sendPaymentDeclineReminderFailure = (error) => ({
    type: SEND_PAYMENT_DECLINE_REMINDER_FAILURE,
    payload: { error },
});


export const refundPayment = (paymentId, business, reason) => {
    return (dispatch) => {
        dispatch(refundPaymentStarted())
        API.patch(`/api/businesses/payments/refund-payment?paymentId=${paymentId}&business=${business}`, { reason })
            .then((res) => {
                dispatch(refundPaymentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, refundPaymentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const refundPaymentStarted = () => ({
    type: REFUND_PAYMENT_STARTED,
});

const refundPaymentSuccess = (data) => ({
    type: REFUND_PAYMENT_SUCCESS,
    payload: { data },
});

const refundPaymentFailure = (error) => ({
    type: REFUND_PAYMENT_FAILURE,
    payload: { error },
});


export const updateBusinessPaymentProfile = (profile) => {
    return (dispatch) => {
        dispatch(updateBusinessPaymentProfileStarted())
        API.patch(`/api/businesses/payment-profile/update`, profile)
            .then((res) => {
                dispatch(updateBusinessPaymentProfileSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, updateBusinessPaymentProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const updateBusinessPaymentProfileStarted = () => ({
    type: UPDATE_BUSINESS_PAYMENT_PROFILE_STARTED,
});

const updateBusinessPaymentProfileSuccess = (data) => ({
    type: UPDATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    payload: { data },
});

const updateBusinessPaymentProfileFailure = (error) => ({
    type: UPDATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});


export const createBusinessPaymentProfile = (profile) => {
    return (dispatch) => {
        dispatch(createBusinessPaymentProfileStarted())
        API.post(`/api/businesses/payment-profile/create`, profile)
            .then((res) => {
                dispatch(createBusinessPaymentProfileSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, createBusinessPaymentProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const createBusinessPaymentProfileStarted = () => ({
    type: CREATE_BUSINESS_PAYMENT_PROFILE_STARTED,
});

const createBusinessPaymentProfileSuccess = (data) => ({
    type: CREATE_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    payload: { data },
});

const createBusinessPaymentProfileFailure = (error) => ({
    type: CREATE_BUSINESS_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});


export const getBusinessPaymentProfile = (business) => {
    return (dispatch) => {
        dispatch(getBusinessPaymentProfileStarted())
        API.get(`/api/businesses/payment-profile/get?business=${business}`)
            .then((res) => {
                dispatch(getBusinessPaymentProfileSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getBusinessPaymentProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const getBusinessPaymentProfileStarted = () => ({
    type: GET_BUSINESS_PAYMENT_PROFILE_STARTED,
});

const getBusinessPaymentProfileSuccess = (data) => ({
    type: GET_BUSINESS_PAYMENT_PROFILE_SUCCESS,
    payload: { data },
});

const getBusinessPaymentProfileFailure = (error) => ({
    type: GET_BUSINESS_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});


export const getBusinessPaymentDetail = (id) => {
    return (dispatch) => {
        dispatch(getBusinessPaymentDetailStarted())
        API.get(`/api/businesses/payments/get-payment-detail?id=${id}`)
            .then((res) => {
                dispatch(getBusinessPaymentDetailSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getBusinessPaymentDetailFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const getBusinessPaymentDetailStarted = () => ({
    type: GET_BUSINESS_PAYMENT_DETAIL_STARTED,
});

const getBusinessPaymentDetailSuccess = (data) => ({
    type: GET_BUSINESS_PAYMENT_DETAIL_SUCCESS,
    payload: { data },
});

const getBusinessPaymentDetailFailure = (error) => ({
    type: GET_BUSINESS_PAYMENT_DETAIL_FAILURE,
    payload: { error },
});


export const createBusinessPaymentIntent = (payment) => {
    return (dispatch) => {
        dispatch(createBusinessPaymentIntentStarted())
        API.post(`/api/businesses/payments/create`, payment)
            .then((res) => {
                dispatch(createBusinessPaymentIntentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, createBusinessPaymentIntentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const createBusinessPaymentIntentStarted = () => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_STARTED,
});

const createBusinessPaymentIntentSuccess = (data) => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_SUCCESS,
    payload: { data },
});

const createBusinessPaymentIntentFailure = (error) => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_FAILURE,
    payload: { error },
});

export const createBusinessPaymentIntentWithVoidCheck = (payment) => {
    return (dispatch) => {
        dispatch(createBusinessPaymentIntentWithVoidCheckStarted())
        API.post(`/api/businesses/payments/create-with-void-check`, payment)
            .then((res) => {
                dispatch(createBusinessPaymentIntentWithVoidCheckSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, createBusinessPaymentIntentWithVoidCheckFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}

const createBusinessPaymentIntentWithVoidCheckStarted = () => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_STARTED,
});

const createBusinessPaymentIntentWithVoidCheckSuccess = (data) => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_SUCCESS,
    payload: { data },
});

const createBusinessPaymentIntentWithVoidCheckFailure = (error) => ({
    type: CREATE_BUSINESS_PAYMENT_INTENT_VOID_CHECK_FAILURE,
    payload: { error },
});



export const getBusinessPaymentList = (business, page) => {
    return (dispatch) => {
        dispatch(getBusinessPaymentListStarted())
        API.get(`api/businesses/payments/get-payment-list?business=${business}&page=${page}`)
            .then((res) => {
                dispatch(getBusinessPaymentListSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getBusinessPaymentListFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
}


const getBusinessPaymentListStarted = () => ({
    type: GET_BUSINESS_PAYMENT_LIST_STARTED,
});

const getBusinessPaymentListSuccess = (data) => ({
    type: GET_BUSINESS_PAYMENT_LIST_SUCCESS,
    payload: { data },
});

const getBusinessPaymentListFailure = (error) => ({
    type: GET_BUSINESS_PAYMENT_LIST_FAILURE,
    payload: { error },
});


// export const getBusinessReportGeneral = (query) => {
//     return (dispatch) => {
//         dispatch(getBusinessReportGeneralStarted())
//         API.get(`/api/businesses/statistics/get-detail?id=${query}`)
//             .then((res) => {
//                 dispatch(getBusinessReportGeneralSuccess(res.data));
//             }).catch((err) => {
//                 catchError(err, dispatch, getBusinessReportGeneralFailure)
//             }).finally(() => setTimeout(function () {
//                 dispatch(removeErrorMessage());
//             }, 3000))
//     }
// };

// const getBusinessReportGeneralStarted = () => ({
//     type: GET_BUSINESS_REPORT_GENERAL_STARTED,
// });

// const getBusinessReportGeneralSuccess = (data) => ({
//     type: GET_BUSINESS_REPORT_GENERAL_SUCCESS,
//     payload: { data },
// });

// const getBusinessReportGeneralFailure = (error) => ({
//     type: GET_BUSINESS_REPORT_GENERAL_FAILURE,
//     payload: { error },
// });

export const getBusinessCompletedAppointment = (business, from, to) => {
    return (dispatch) => {
        dispatch(getBusinessCompletedAppointmentStarted())
        API.get(`/api/businesses/payments/get-completed-appointments?business=${business}&from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getBusinessCompletedAppointmentSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getBusinessCompletedAppointmentFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const getBusinessCompletedAppointmentStarted = () => ({
    type: GET_COMPLETED_APPOINTMENTS_STARTED,
});

const getBusinessCompletedAppointmentSuccess = (data) => ({
    type: GET_COMPLETED_APPOINTMENTS_SUCCESS,
    payload: { data },
});

const getBusinessCompletedAppointmentFailure = (error) => ({
    type: GET_COMPLETED_APPOINTMENTS_FAILURE,
    payload: { error },
});

export const getBusinessVoidProfile = (bid) => {
    return (dispatch) => {
        dispatch(getBusinessVoidProfileStarted())
        API.post(`/api/businesses/payments/get-void-profile`, { bid })
            .then((res) => {
                dispatch(getBusinessVoidProfileSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, getBusinessVoidProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const getBusinessVoidProfileStarted = () => ({
    type: GET_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
});

const getBusinessVoidProfileSuccess = (data) => ({
    type: GET_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    payload: { data },
});

const getBusinessVoidProfileFailure = (error) => ({
    type: GET_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});

export const createBusinessVoidProfile = (voidChequeProfile) => {
    return (dispatch) => {
        dispatch(createBusinessVoidProfileStarted())
        API.post(`/api/businesses/payments/create-void-profile`, voidChequeProfile)
            .then((res) => {
                dispatch(createBusinessVoidProfileSuccess(res.data));
            }).catch((err) => {
                catchError(err, dispatch, createBusinessVoidProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const createBusinessVoidProfileStarted = () => ({
    type: CREATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
});

const createBusinessVoidProfileSuccess = (data) => ({
    type: CREATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    payload: data,
});

const createBusinessVoidProfileFailure = (error) => ({
    type: CREATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});

export const updateBusinessVoidProfile = (voidChequeProfile) => {
    return (dispatch) => {
        dispatch(updateBusinessVoidProfileStarted())
        API.post(`/api/businesses/payments/update-void-profile`, voidChequeProfile)
            .then((res) => {
                dispatch(updateBusinessVoidProfileSuccess(res.data.customerProfile));
            }).catch((err) => {
                catchError(err, dispatch, updateBusinessVoidProfileFailure)
            }).finally(() => setTimeout(function () {
                dispatch(removeErrorMessage());
            }, 3000))
    }
};

const updateBusinessVoidProfileStarted = () => ({
    type: UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_STARTED,
});

const updateBusinessVoidProfileSuccess = (data) => ({
    type: UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_SUCCESS,
    payload: data
});

const updateBusinessVoidProfileFailure = (error) => ({
    type: UPDATE_BUSINESS_VOID_PAYMENT_PROFILE_FAILURE,
    payload: { error },
});

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const logoutUser = () => ({
    type: LOGOUT,
});

//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};
