import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loading from "../../../layouts/Loading/Loading";
import {
    getGiftCardSettings,
    updateGiftCardSettings,
} from "../../../redux/actions/gift-card-settings/giftCardSettings";

const GiftCard = (props) => {
    const [stripeSecretKey, setStripeSecretKey] = useState("");
    const [stripeWebhookSecret, setStripeWebhookSecret] = useState("");
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const dispatch = useDispatch();
    const { settings, loading } = useSelector(
        (state) => state.giftCardSettings
    );

    useEffect(() => {
        dispatch(getGiftCardSettings(props.business._id));
    }, [dispatch, props.business._id]);

    useEffect(() => {
        if (settings) {
            setStripeSecretKey(settings.stripeSecretKey);
            setStripeWebhookSecret(settings.stripeWebhookSecret);
        }
    }, [settings]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(
            updateGiftCardSettings({
                stripeSecretKey,
                stripeWebhookSecret,
                business: props.business._id,
            })
        );
    };
    if (loading) {
        return <Loading />;
    }
    return (
        <div className='container mt-5'>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId='stripeSecretKey'>
                    <Form.Label>Stripe Secret Key</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter your Stripe Secret Key'
                        value={stripeSecretKey}
                        onChange={(e) => {
                            setStripeSecretKey(e.target.value);
                            setDisableSaveButton(false);
                        }}
                        required
                    />
                </Form.Group>
                <Form.Group controlId='stripeWebhookSecret'>
                    <Form.Label>Stripe Webhook Secret Signature</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter your Stripe Webhook Secret Signature'
                        value={stripeWebhookSecret}
                        onChange={(e) => {
                            setStripeWebhookSecret(e.target.value);
                            setDisableSaveButton(false);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Stripe Webhook URL</Form.Label>
                    <Form.Control
                        type='text'
                        readOnly
                        placeholder='Here is Stripe Webhook URL'
                        value={
                            "https://apiv2.dashbooking.com/api/gift-card/stripe-listener"
                        }
                    />
                    <p className='text-warning'>
                        Add Event: payment_intent.succeeded for Webhook
                    </p>
                </Form.Group>
                <Button
                    disabled={disableSaveButton}
                    variant='primary'
                    type='submit'
                >
                    Save
                </Button>
                { }
            </Form>
        </div>
    );
};

export default GiftCard;
