import {
    GET_DEPOSIT_SETTINGS_STARTED,
    GET_DEPOSIT_SETTINGS_SUCCESS,
    GET_DEPOSIT_SETTINGS_FAILURE,
    UPDATE_DEPOSIT_SETTINGS_STARTED,
    UPDATE_DEPOSIT_SETTINGS_SUCCESS,
    UPDATE_DEPOSIT_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from '../../constants/deposit-settings/depositSettings'


import API from "../../../utils/API/API";


export const getDepositSettings = (id) => {
    return dispatch => {
        dispatch(getDepositSettingsStarted())
        API.get(`/api/deposit-settings/${id}`)
            .then(res => {
                dispatch(getDepositSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, getDepositSettingsFailure);
            })
    }
}

const getDepositSettingsStarted = () => ({
    type: GET_DEPOSIT_SETTINGS_STARTED
})

const getDepositSettingsSuccess = (data) => ({
    type: GET_DEPOSIT_SETTINGS_SUCCESS,
    payload: {
        settings: data
    }
})

const getDepositSettingsFailure = (error) => ({
    type: GET_DEPOSIT_SETTINGS_FAILURE,
    payload: { error }
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


export const updateDepositSettings = (settings) => {
    return dispatch => {
        dispatch(updateDepositSettingsStarted())
        API.post(`/api/deposit-settings/update`, settings)
            .then(res => {
                dispatch(updateDepositSettingsSuccess(res.data))
            })
            .catch((err) => {
                catchError(err, dispatch, updateDepositSettingsFailure);
            })
    }
}

const updateDepositSettingsStarted = () => ({
    type: UPDATE_DEPOSIT_SETTINGS_STARTED
})

const updateDepositSettingsSuccess = (data) => ({
    type: UPDATE_DEPOSIT_SETTINGS_SUCCESS,
    payload: {
        settings: data
    }
})

const updateDepositSettingsFailure = (error) => ({
    type: UPDATE_DEPOSIT_SETTINGS_FAILURE,
    payload: { error }
})


const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
    }

    dispatch(nextFunction(message));
    setTimeout(function () {
        dispatch(removeErrorMessage());
    }, 2000);
}