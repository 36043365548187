import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Accordion, Card, Form } from "react-bootstrap";
import { PaymentDetail } from "./PaymentDetail";
import Loading from "../../../layouts/Loading/Loading";
import { refundPayment, sendPaymentDeclineReminder } from "../../../redux/actions/business-payment/business-payment";
class PaymentDetailModal extends Component {
    state = {
        refundReason: "",
    };

    updateRefundReason = (value) => {
        this.setState({
            refundReason: value,
        });
    };

    handleSendDeclineReminder = () => {
        const confirmed = window.confirm("Confirm send in-app notification and email to the business?");
        if (confirmed) {
            this.props.sendPaymentDeclineReminder(this.props.business)
        }
    }

    handleRefundSubmit = (e) => {
        e.preventDefault();
        const { refundReason } = this.state;
        const businessId = this.props.business;
        const paymentId = this.props.paymentDetail.businessPayment._id;
        this.props.refundPayment(paymentId, businessId, refundReason);
    };

    render() {
        const {
            modalShow,
            showModal,
            paymentDetail,
            loading,
            refundPayment,
            userRole,
            sendPaymentDeclineReminder,
            removePayment,
            ...rest
        } = this.props;
        return (
            <Modal
                {...rest}
                show={this.props.modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.props.showModal(false)}
            // centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Payment Detail
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "scroll", height: "600px" }}>
                    {loading && <Loading />}
                    {!loading &&
                        paymentDetail &&
                        paymentDetail.businessPayment && (
                            <PaymentDetail
                                businessPayment={paymentDetail.businessPayment}
                                closeModal={this.props.showModal}
                                removePayment={this.props.removePayment}
                            />
                        )}

                    {!loading && paymentDetail &&
                        paymentDetail.businessPayment &&
                        (paymentDetail.businessPayment.status === "Failed" || paymentDetail.businessPayment.status === "Chargeback")
                        && (
                            <Button onClick={() => this.handleSendDeclineReminder()}>Send a payment declined reminder</Button>
                        )}

                    {!loading && (
                        <Accordion>
                            {["admin", "accountant"].includes(
                                userRole
                            ) &&
                                paymentDetail &&
                                paymentDetail.businessPayment &&
                                paymentDetail.paymentIntent &&
                                ["succeeded", "Success"].includes(
                                    paymentDetail.businessPayment.status
                                ) && (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                className="text-warning"
                                                eventKey="0"
                                            >
                                                Refund this payment
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Form
                                                    className="p-3"
                                                    onSubmit={(e) =>
                                                        this.handleRefundSubmit(
                                                            e
                                                        )
                                                    }
                                                >
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Cancel reason
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            required={true}
                                                            placeholder="Enter reason"
                                                            onChange={(e) =>
                                                                this.updateRefundReason(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Button
                                                        variant="danger"
                                                        type="submit"
                                                    >
                                                        Refund payment
                                                    </Button>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}

                            <Card>
                                <Card.Header>
                                    {paymentDetail &&
                                        paymentDetail.paymentIntent &&
                                        paymentDetail.paymentIntent.charges &&
                                        paymentDetail.paymentIntent.charges.data &&
                                        paymentDetail.paymentIntent.charges.data.length > 0 &&
                                        paymentDetail.paymentIntent.charges.data[0].outcome &&
                                        paymentDetail.paymentIntent.charges.data[0].outcome.seller_message && (
                                            <div>
                                                <strong>Bank Message:</strong> {paymentDetail.paymentIntent.charges.data[0].outcome.seller_message}
                                            </div>
                                        )}
                                    {paymentDetail &&
                                        paymentDetail.paymentIntent &&
                                        paymentDetail.paymentIntent.charges &&
                                        paymentDetail.paymentIntent.charges.data &&
                                        paymentDetail.paymentIntent.charges.data.length > 0 &&
                                        paymentDetail.paymentIntent.charges.data[0].payment_method_details &&
                                        paymentDetail.paymentIntent.charges.data[0].payment_method_details.card && (
                                            <div>
                                                <strong>Card Details:</strong> {paymentDetail.paymentIntent.charges.data[0].payment_method_details.card.brand}  {paymentDetail.paymentIntent.charges.data[0].payment_method_details.card.last4}
                                                <br />

                                            </div>
                                        )}
                                    <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey="1"
                                    >
                                        JSON Data
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <pre
                                            style={{
                                                fontSize: ".65rem",
                                                padding: ".5rem",
                                            }}
                                        >
                                            {paymentDetail &&
                                                paymentDetail.paymentIntent &&
                                                JSON.stringify(
                                                    paymentDetail.paymentIntent,
                                                    null,
                                                    2
                                                )}
                                            {paymentDetail &&
                                                paymentDetail.transactionRotessa &&
                                                JSON.stringify(
                                                    paymentDetail.transactionRotessa,
                                                    null,
                                                    2
                                                )}
                                        </pre>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.showModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userRole: state.authenticate.user.role,
    business: state.business.business._id,
    paymentDetail: state.businessReport.paymentDetail,
    loading: state.businessReport.loadingPaymentDetail,
});

const mapDispatchToProps = (dispatch) => {
    return {
        refundPayment: (paymentId, business, reason) =>
            dispatch(refundPayment(paymentId, business, reason)),
        sendPaymentDeclineReminder: (business) =>
            dispatch(sendPaymentDeclineReminder(business)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailModal);
