import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Container, Button, Badge, Alert } from "react-bootstrap";
import { getBusinessPaymentList } from "../../../redux/actions/business-payment/business-payment";
import moment from "moment";
import { getBusinessPaymentDetail } from "../../../redux/actions/business-payment/business-payment";
import PaymentDetailModal from "./PaymentDetailModal";
import API from "../../../utils/API/API";
class PaymentHistory extends Component {
    state = {
        modalShow: false,
        page: 1,
        count: 0,
        paymentList: [],
        loading: false
    };
    showModal = (value) => {
        this.setState({
            modalShow: value,

        });
    };

    removePayment = (id) => {
        const { paymentList } = this.state;
        const newPaymentList = paymentList.filter(payment => payment._id !== id);
        this.setState({ paymentList: newPaymentList, count: this.state.count - 1 });
    }

    fetchingPayment = async (page = 0) => {
        this.setState({ loading: true, error: null });
        const { business } = this.props;

        try {
            const response = await API.get(`/api/businesses/payments/get-payment-list`, {
                params: { business: business._id, page }
            });

            if (response && response.data) {
                const { count, paymentList } = response.data;
                this.setState({ count, paymentList: page === 0 ? paymentList : [...this.state.paymentList, ...paymentList] });
            }
        } catch (error) {
            console.error(error);
            const errorMessage = error.response?.data?.message || 'Unknown Error';
            this.setState({ error: errorMessage });
        } finally {
            this.setState({ loading: false });
        }
    }

    displayPaymentType = (payment) => {
        if (payment.paymentType) {
            if (payment.paymentType === "Credit Card") {
                return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
            }
            if (payment.paymentType === "Void Cheque") {
                return <>Void Cheque <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
            }
        }
        else if (payment.paymentIntentId) {
            return <>Credit Card <img width="30px" src="/assets/images/credit-card.svg" alt="Credit Card" /></>
        }
        else if (payment.transactionRotessaId) {
            return <>Void Cheque <img width="30px" src="/assets/images/cheque.svg" alt="Void Cheque" /></>
        }
    }

    displayPaymentStatus = (paymentStatus) => {
        if (paymentStatus === "Pending") {
            return <Badge variant="warning">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Success") {
            return <Badge variant="success">{paymentStatus}</Badge>
        }
        else if (paymentStatus === "Failed") {
            return <Badge variant="danger">{paymentStatus}</Badge>
        }
        else return <Badge variant="secondary">{paymentStatus}</Badge>
    }

    componentDidMount() {
        if (this.props.business) {
            this.fetchingPayment()
            // this.props.getBusinessPaymentList(this.props.business._id, 0);
        }
    }

    getPaymentDetail = (id) => {
        this.props.getBusinessPaymentDetail(id);
        this.showModal(true);
    };

    render() {
        const { paymentList, count, loading, error } = this.state;
        // const { payments, count } = this.props;
        const { modalShow } = this.state;
        return (
            <Container className="mt-3">
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>Created At</th>
                            <th>Total</th>
                            <th>Method</th>
                            <th>Status</th>
                            <th>Submited</th>
                            <th>Detail</th>

                        </tr>
                    </thead>
                    <tbody>
                        {paymentList.map((payment) => {
                            return (
                                <tr key={payment._id}>
                                    <td>
                                        {moment(payment.createdAt).format(
                                            "MMM DD, YYYY hh:mma"
                                        )}
                                    </td>
                                    <td>{Math.round(payment.total * 100) / 100}</td>
                                    <td>
                                        {this.displayPaymentType(payment)}

                                    </td>
                                    <td>{this.displayPaymentStatus(payment.status)}</td>
                                    <td>{
                                        payment.isSubmited
                                            ? <Badge variant="success">Yes</Badge>
                                            : <Badge variant="warning">No</Badge>
                                    }
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                this.getPaymentDetail(
                                                    payment._id
                                                )
                                            }
                                        >
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                {paymentList.length < count && (
                    <div className="text-center mt-3">
                        <Button disabled={loading} onClick={() => {
                            this.setState({ page: this.state.page + 1 });
                            this.fetchingPayment(this.state.page + 1)
                        }}>
                            Load More
                        </Button>
                    </div>
                )}
                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                <PaymentDetailModal
                    showModal={this.showModal}
                    modalShow={modalShow}
                    removePayment={this.removePayment}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.business.business,
    payments: state.businessReport.payments,
    count: state.businessReport.count,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessPaymentList: (businessId, page) =>
            dispatch(getBusinessPaymentList(businessId, page)),
        getBusinessPaymentDetail: (id) =>
            dispatch(getBusinessPaymentDetail(id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
