import React, { useEffect, useState } from 'react'
import Loading from '../../../layouts/Loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { getCancellationSettings, updateCancellationSettings } from '../../../redux/actions/cancellation-settings/cancellationSettings'
import { Button, Col, Form, Row } from 'react-bootstrap'

const CancellationSettings = (props) => {

    const [cancelSettings, setCancelSettings] = useState({
        cancelBefore: 0,
        allowCancelWithin: 30,
        privacyPolicyUrl: "",
        termsAndConditionsUrl: "",
    })

    const [disabledSubimit, setDisabledSubimit] = useState(true)

    const { loading, cancellationSettings } = useSelector(state => state.cancellationSettings)
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(updateCancellationSettings(cancelSettings))
    }

    const handleChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;

        const newSettings = { ...cancelSettings };
        newSettings[field] = value;

        setCancelSettings(newSettings);
        setDisabledSubimit(false)
    };

    useEffect(() => {
        dispatch(getCancellationSettings(props.business._id))
    }, [dispatch, props.business._id])

    useEffect(() => {
        if (cancellationSettings) {
            setCancelSettings(cancellationSettings);
        }
    }, [cancellationSettings]);

    if (loading) {
        return <Loading />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mt-3">
                <Col>
                    <Form.Group controlId="cancelBefore">
                        <Form.Label>Cancel Before (Hours)</Form.Label>
                        <Form.Control
                            type="number"
                            name="cancelBefore"
                            value={cancelSettings?.cancelBefore}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="allowCancelWithin">
                        <Form.Label>Allow cancel within (Minutes)</Form.Label>
                        <Form.Control
                            type="number"
                            name="allowCancelWithin"
                            value={cancelSettings?.allowCancelWithin}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>


            <Row class="mt-3">
                <Col>
                    <Form.Group controlId="privacyPolicyUrl">
                        <Form.Label>Privacy Policy URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="privacyPolicyUrl"
                            value={cancelSettings?.privacyPolicyUrl}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="termsAndConditionsUrl">
                        <Form.Label>Terms and Conditions</Form.Label>
                        <Form.Control
                            type="text"
                            name="termsAndConditionsUrl"
                            value={cancelSettings?.termsAndConditionsUrl}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group controlId="depositPolicy">
                        <Form.Label>Deposit Policy</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            name="depositPolicy"
                            value={cancelSettings?.depositPolicy}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>


            <Button disabled={disabledSubimit} variant="primary" type="submit">
                Save
            </Button>
        </Form>
    )
}

export default CancellationSettings