import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    getPayments,
    getPaymentDetails,
} from "../../redux/actions/payment/payment";
import PaymentsTable from "../../components/payments/PaymentsTable";
import PaymentDetailsModal from "../../components/payments/PaymentDetailsModal";
import Loading from "../../layouts/Loading/Loading";
import Pages from "../../components/common/page/Pages";
import moment from "moment";

class Payments extends Component {
    state = {
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        status: "",
        page: 1,
        isModalShowing: false,
    };

    componentDidMount() {
        let { startDate, endDate, status, page } = this.state;
        let query = { from: startDate, to: endDate, status, page };
        this.props.getPayments(query);
    }

    handleCalendarClose = (event, picker) => {
        let { startDate, endDate, status } = this.state;
        startDate = picker.startDate.format("YYYY-MM-DD");
        endDate = picker.endDate.format("YYYY-MM-DD");

        this.setState({ startDate, endDate, page: 1 }, () => {
            let query = {
                from: startDate,
                to: endDate,
                status: status,
                page: 1,
            };
            this.props.getPayments(query);
        });
    };

    handlePageChange = (page) => {
        let { startDate, endDate, status } = this.state;
        this.setState({ page }, () => {
            let query = { from: startDate, to: endDate, status: status, page };
            this.props.getPayments(query);
        });
    };

    handleShowModal = (value) => {
        this.setState({
            isModalShowing: value,
        });
    };

    handleViewDetails = (paymentId) => {
        this.props.getPaymentDetails(paymentId);
        this.handleShowModal(true);
    };

    handleChooseStatus = (eventKey) => {
        this.setState({ status: eventKey, page: 1 }, () => {
            let { startDate, endDate, status, page } = this.state;
            let query = { from: startDate, to: endDate, status, page };
            this.props.getPayments(query);
        });
    };

    render() {
        const { paymentsCount, loading, summary, role } = this.props;
        const { startDate, endDate, status, page, isModalShowing } = this.state;

        if (loading) {
            return <Loading />;
        }
        return (
            <div>
                <Row>
                    <Col>
                        <h1>Payments</h1>
                    </Col>
                </Row>

                <Row
                    style={{ marginTop: "20px" }}
                    className="justify-content-md-center"
                >
                    <Col>
                        <DateRangePicker
                            initialSettings={{
                                startDate: startDate,
                                endDate: endDate,
                                opens: "left",
                                showDropdowns: true,
                                autoApply: true,
                                locale: { format: "YYYY-MM-DD" },
                                ranges: {
                                    "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                    ],
                                    "Last Month": [
                                        moment()
                                            .subtract(1, "month")
                                            .startOf("month"),
                                        moment()
                                            .subtract(1, "month")
                                            .endOf("month"),
                                    ],
                                    Today: [moment(), moment()],
                                    Yesterday: [
                                        moment().subtract(1, "days"),
                                        moment().subtract(1, "days"),
                                    ],
                                    "Last 7 Days": [
                                        moment().subtract(6, "days"),
                                        moment(),
                                    ],
                                    "Last 30 Days": [
                                        moment().subtract(29, "days"),
                                        moment(),
                                    ],
                                    "This Year": [
                                        moment().startOf("year"),
                                        moment().endOf("year"),
                                    ],
                                },
                            }}
                            onHide={this.handleCalendarClose}
                        >
                            <Button variant="outline-primary">
                                {startDate} - {endDate}
                            </Button>
                        </DateRangePicker>
                    </Col>
                    {["admin", "owner"].includes(role) && <Col>
                        <Dropdown
                            style={{ float: "right" }}
                            onSelect={(eventKey) =>
                                this.handleChooseStatus(eventKey)
                            }
                        >
                            <Dropdown.Toggle variant="primary">
                                Status: {status}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ fontSize: "16px" }}>
                                <Dropdown.Item eventKey="">
                                    All Status
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Success">
                                    Success
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Failed">
                                    Failed
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Pending">
                                    Pending
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Canceled">
                                    Canceled
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>}
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    {paymentsCount === 0 ? (
                        <h5>No Data</h5>
                    ) : (
                        <Col>
                            <div className="p-3 border border-primary">
                                <h3>Total: {paymentsCount} transations</h3>

                                {summary.length > 0 && (
                                    <Row >
                                        {summary.map((type) => {
                                            return (
                                                <Col key={type._id} md={6}>
                                                    <p>
                                                        {type.count} {type._id}{" "}
                                                        : ${type.totalAmount}
                                                    </p>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </div>

                            <PaymentsTable
                                handleViewDetails={this.handleViewDetails}
                            />
                        </Col>
                    )}
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        <Pages
                            page={page}
                            countPages={Math.ceil(paymentsCount / 10)}
                            handlePageChange={this.handlePageChange}
                        />
                    </Col>
                </Row>
                <PaymentDetailsModal
                    handleShowModal={this.handleShowModal}
                    isModalShowing={isModalShowing}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    role: state.authenticate.user?.role,
    payments: state.payment.payments,
    summary: state.payment.summary,
    paymentsCount: state.payment.paymentsCount,
    loading: state.payment.loading,
    error: state.payment.error,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPayments: (query) => dispatch(getPayments(query)),
        getPaymentDetails: (paymentId) =>
            dispatch(getPaymentDetails(paymentId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
