import { combineReducers } from 'redux'
import authenticate from "./employee-account/login"
import account from "./business/account"
import businessStatus from './admin/business-status'
import businessType from './admin/business-type'
import province from './admin/province'
import city from './admin/city'
import employee from './admin/employee'
import resetPassword from './employee-account/reset-password'
import business from './business/business'
import provinceCity from "./province-city/province-city"
import staff from "./business/staff"
import note from "./business/note"
import menu from "./business/menu"
import searchBusiness from "./business/business-search"
import businessHour from "./business/business-hour"
import businessAccount from "./business/business-account"
import staffHour from "./business/staff-hour"
import businessSummary from "./business/business-summary"
import businessTax from "./business/taxes"
import previewBusiness from "./business/preview-business"
import client from "./client/client"
import businessReport from "./business-payment/business-payment"
// import businessStatistics from "./business-statistics/business-statistics"
import appointment from './appointment/appointment'
import notification from './notification/notification'
import website from './business/website'
import payment from './payment/payment'
import googleBookingInfo from './googleBookingInfo/googleBookingInfo'
import giftCardSettings from './gift-card-settings/giftCardSettings'
import cancellationSettings from './cancellation-settings/cancellationSettings'
import depositSettings from './deposit-settings/depositSettings'
export default combineReducers({
    authenticate,
    account,
    businessStatus,
    businessType,
    province,
    city,
    employee,
    resetPassword,
    business,
    provinceCity,
    staff,
    menu,
    note,
    businessAccount,
    searchBusiness,
    businessHour,
    staffHour,
    businessSummary,
    businessTax,
    previewBusiness,
    client,
    businessReport,
    // businessStatistics,
    appointment,
    notification,
    website,
    payment,
    googleBookingInfo,
    giftCardSettings,
    cancellationSettings,
    depositSettings
})
