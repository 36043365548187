import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Tab } from "react-bootstrap";
import GeneralInfoForm from "./general-infomation/GeneralInfoForm";
import LocationInfoForm from "./location-information/LocationInfoForm";
// import Loading from "../../layouts/Loading/Loading";
// import { getBusinessDetail } from "../../redux/actions/business/business";
// import { getBusinessType } from "../../redux/actions/admin/business-type";
// import { getBusinessStatus } from "../../redux/actions/admin/business-status";
// import { getAllProvincesCities } from "../../redux/actions/province-city/province-city";
// import { getTaxes } from "../../redux/actions/business/taxes"
import AboutInfoForm from "./about-information/AboutInfoForm";
import ImageContainer from "./image-information/ImageContainer";
import ScoreInfoForm from "./score-information/ScoreInfoForm";
// import MenuInfoContainer from "./menu-information/MenuInfoContainer";
// import StaffContainer from "./staff-information/StaffContainer";
import BusinessAccountContainer from "./account-information/BusinessAccountContainer";
import NoteInfoContainer from "./note-information/NoteContainer";
// import HourContainer from "./hour-information/HourContainer";
import PublishingContainer from "./publishing/PublishingContainer"
// import TaxContainer from "./taxes/TaxContainer";
// import ActiveFeaturesContainer from "./active-features/ActiveFeaturesContainer";
import ActivateContainer from "./activate/ActivateContainer";
import SocialMedia from "./social-media/SocialMedia"
import Website from "./website-information/Website"
import AppointmentSettingForm from "./appointment-settings/AppointmentSettingForm";

import { ErrorMessageToast } from "../ErrorSuccess/ErrorMessageToast";
import GoogleBookingForm from "./googleBooking/GoogleBookingForm";
import GiftCard from "./gift-card/GiftCard";
import Reviews from "./reviews/Reviews";
import { NotificationContainer } from "./notification/notificationContainer"
import SenderPhonesForm from "./sender-phones/SenderPhones";
import CancellationSettings from "./cancellation-settings/CancellationSettings";
import OnlineDepositSettings from "./online-deposit/OnlineDepositSettings";
import CopyMenu from "./copy-menu/CopyMenu";
class BusinessDetailContentContainer extends Component {
    state = {
        business: {}
    };

    // componentDidMount() {
    //     const businessId = this.props.businessId;
    //     this.props.getBusinessDetail(businessId);
    //     this.props.getBusinessType();
    //     this.props.getBusinessStatus();
    //     this.props.getAllProvincesCities();
    //     // this.props.getTaxes(businessId)
    //     if (this.props.business) {
    //         this.setState({
    //             business: this.props.business
    //         });
    //     }
    // }

    render() {
        const {
            business,
            businessError,
            businessMessage,

            businessTypesMessage,
            businessTypesError,


            businessStatusesMessage,
            businessStatusesError,

            provinceCityMessage,
            provinceCityError,

            accountError,
            accountMessage,

            businessAccountError,
            businessAccountMessage,

            noteError,
            noteMessage,

            websiteError,
            websiteMessage,

            googleBookingInfoError,
            googleBookingInfoMessage,

            giftCardSettingsError,
            giftCardSettingsMessage,


            depositSettingsError,
            depositSettingsMessage,

            cancellationSettingsError,
            cancellationSettingsMessage,

            currentTab
        } = this.props;




        return (
            <Col sm={9}>
                <Tab.Content>
                    {business && <h2>{business.name}</h2>}
                    {currentTab === "general" && (
                        <Tab.Pane eventKey='general'>
                            <GeneralInfoForm
                                business={business}
                            />
                        </Tab.Pane>
                    )}
                    {currentTab === "appointmentSettings" && (
                        <Tab.Pane eventKey='appointmentSettings'>
                            <AppointmentSettingForm business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "location" && (
                        <Tab.Pane eventKey='location'>
                            <LocationInfoForm business={business} />
                        </Tab.Pane>
                    )}{currentTab === "googleBooking" && (
                        <Tab.Pane eventKey='googleBooking'>
                            <GoogleBookingForm business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "phoneSenders" && (
                        <Tab.Pane eventKey='phoneSenders'>
                            <SenderPhonesForm business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "about" && (
                        <Tab.Pane eventKey='about'>
                            <AboutInfoForm business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "image" && (
                        <Tab.Pane eventKey='image'>
                            <ImageContainer business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "scores" && (
                        <Tab.Pane eventKey='scores'>
                            <ScoreInfoForm business={business} />
                        </Tab.Pane>
                    )}
                    {currentTab === "publishing" && (
                        <Tab.Pane eventKey='publishing'>
                            <PublishingContainer /></Tab.Pane>
                    )}
                    {currentTab === "accounts" && (
                        <Tab.Pane eventKey='accounts'><BusinessAccountContainer /></Tab.Pane>
                    )}
                    {currentTab === "notes" && (
                        <Tab.Pane eventKey='notes'><NoteInfoContainer business={business} /></Tab.Pane>
                    )}
                    {/* {currentTab === "activeFeatures" && (
                        <Tab.Pane eventKey='activeFeatures'><ActiveFeaturesContainer business={business} /></Tab.Pane>
                    )} */}
                    {currentTab === "activate" && (
                        <Tab.Pane eventKey='activate'><ActivateContainer business={business} /></Tab.Pane>
                    )}
                    {currentTab === "socialMedia" && (
                        <Tab.Pane eventKey='socialMedia'><SocialMedia business={business} /></Tab.Pane>
                    )}
                    {currentTab === "website" && (
                        <Tab.Pane eventKey='website'><Website business={business} /></Tab.Pane>
                    )}
                    {currentTab === "giftCard" && (
                        <Tab.Pane eventKey='giftCard'><GiftCard business={business} /></Tab.Pane>
                    )}
                    {currentTab === "deposit" && (
                        <Tab.Pane eventKey='deposit'><OnlineDepositSettings business={business} /></Tab.Pane>
                    )}
                    {currentTab === "reviews" && (
                        <Tab.Pane eventKey='reviews'><Reviews business={business} /></Tab.Pane>)}
                    {currentTab === "notification" && (
                        <Tab.Pane eventKey='notification'><NotificationContainer business={business} /></Tab.Pane>
                    )}
                    {currentTab === "copyMenu" && (
                        <Tab.Pane eventKey='copyMenu'><CopyMenu business={business} /></Tab.Pane>
                    )}
                    {currentTab === "cancellationSettings" && (
                        <Tab.Pane eventKey='cancellationSettings'><CancellationSettings business={business} /></Tab.Pane>
                    )}

                </Tab.Content>
                <div className="error-message-container" >
                    {(businessError || businessMessage) && (
                        <ErrorMessageToast error={businessError} message={businessMessage} />
                    )}
                    {(businessTypesError || businessTypesMessage) && (
                        <ErrorMessageToast error={businessTypesError} message={businessTypesMessage} />
                    )}
                    {(businessStatusesError || businessStatusesMessage) && (
                        <ErrorMessageToast error={businessStatusesError} message={businessStatusesMessage} />
                    )}
                    {(provinceCityError || provinceCityMessage) && (
                        <ErrorMessageToast error={provinceCityError} message={provinceCityMessage} />
                    )}
                    {(accountError || accountMessage) && (
                        <ErrorMessageToast error={accountError} message={accountMessage} />
                    )}
                    {(businessAccountError || businessAccountMessage) && (
                        <ErrorMessageToast error={businessAccountError} message={businessAccountMessage} />
                    )}
                    {(noteError || noteMessage) && (
                        <ErrorMessageToast error={noteError} message={noteMessage} />
                    )}
                    {(websiteError || websiteMessage) && (
                        <ErrorMessageToast error={websiteError} message={websiteMessage} />
                    )}
                    {(googleBookingInfoError || googleBookingInfoMessage) && (
                        <ErrorMessageToast error={googleBookingInfoError} message={googleBookingInfoMessage} />
                    )}
                    {(giftCardSettingsError || giftCardSettingsMessage) && (
                        <ErrorMessageToast error={giftCardSettingsError} message={giftCardSettingsMessage} />
                    )}
                    {(depositSettingsError || depositSettingsMessage) && (
                        <ErrorMessageToast error={depositSettingsError} message={depositSettingsMessage} />
                    )}
                    {(cancellationSettingsError || cancellationSettingsMessage) && (
                        <ErrorMessageToast error={cancellationSettingsError} message={cancellationSettingsMessage} />
                    )}
                </div>

            </Col>
        );
    }
}

const mapStateToProps = state => ({

    business: state.business.business,
    businessError: state.business.error,
    businessMessage: state.business.message,


    businessTypesError: state.businessType.error,
    businessTypesMessage: state.businessType.message,


    businessStatusesError: state.businessStatus.error,
    businessStatusesMessage: state.businessStatus.message,

    provinceCityError: state.provinceCity.error,
    provinceCityMessage: state.provinceCity.message,

    accountError: state.account.error,
    accountMessage: state.account.message,

    businessAccountError: state.businessAccount.error,
    businessAccountMessage: state.businessAccount.message,

    noteError: state.note.error,
    noteMessage: state.note.message,

    websiteError: state.website.error,
    websiteMessage: state.website.message,

    googleBookingInfoError: state.googleBookingInfo.error,
    googleBookingInfoMessage: state.googleBookingInfo.message,

    giftCardSettingsError: state.giftCardSettings.error,
    giftCardSettingsMessage: state.giftCardSettings.message,

    depositSettingsError: state.depositSettings.error,
    depositSettingsMessage: state.depositSettings.message,

    cancellationSettingsError: state.cancellationSettings.error,
    cancellationSettingsMessage: state.cancellationSettings.message,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessDetailContentContainer);
