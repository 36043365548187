import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loading from "../../../layouts/Loading/Loading";
import { getDepositSettings, updateDepositSettings } from "../../../redux/actions/deposit-settings/depositSettings";


const OnlineDepositSettings = (props) => {
    const [stripeSecretKey, setStripeSecretKey] = useState("");
    const [stripeWebhookSecret, setStripeWebhookSecret] = useState("");
    const [depositTypes, setDepositTypes] = useState([]);
    const [requiredAt, setRequiredAt] = useState(0)
    const [depositRate, setDepositRate] = useState(0)
    const [isRequiredDeposit, setIsRequiredDeposit] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const dispatch = useDispatch();
    const { settings, loading } = useSelector(
        (state) => state.depositSettings
    );

    useEffect(() => {
        dispatch(getDepositSettings(props.business._id));
    }, [dispatch, props.business._id]);

    useEffect(() => {
        if (settings?.depositSettings) {
            setStripeSecretKey(settings.depositSettings.stripeSecretKey);
            setStripeWebhookSecret(settings.depositSettings.stripeWebhookSecret);
            setDepositRate(settings.depositSettings.depositRate);
            setRequiredAt(settings.depositSettings.requiredAt);
            setDepositTypes(settings.depositSettings.depositTypes);
            setIsRequiredDeposit(settings.isRequiredDeposit)
        }
    }, [settings]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const depositSettings = {
            stripeSecretKey,
            stripeWebhookSecret,
            depositRate,
            requiredAt,
            depositTypes,
            isRequiredDeposit,
            business: props.business._id,
        }
        dispatch(updateDepositSettings(depositSettings));

    };
    if (loading) {
        return <Loading />;
    }


    return (
        <div className='container mt-5'>
            <Form onSubmit={handleSubmit}>
                <Form.Group >
                    <Form.Label>Enable Deposit Online </Form.Label>
                    <Form.Switch id="isRequiredDeposit" checked={isRequiredDeposit} onChange={(e) => {
                        setIsRequiredDeposit(e.target.checked)
                        setDisableSaveButton(false);
                    }
                    } />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Deposit Types</Form.Label>
                    <Row>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                id="singleCheckbox"
                                label="Single"
                                value="single"
                                disabled={!isRequiredDeposit}
                                checked={depositTypes.includes('single')}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const selectedValues = [...depositTypes];

                                    if (selectedValues.includes(value)) {
                                        const index = selectedValues.indexOf(value);
                                        selectedValues.splice(index, 1);
                                    } else {
                                        selectedValues.push(value);
                                    }

                                    setDepositTypes(selectedValues);
                                    setDisableSaveButton(false);
                                }}
                            />

                        </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Group"
                                value="group"
                                id="groupCheckbox"
                                disabled={!isRequiredDeposit}
                                checked={depositTypes.includes('group')}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const selectedValues = [...depositTypes];

                                    if (selectedValues.includes(value)) {
                                        const index = selectedValues.indexOf(value);
                                        selectedValues.splice(index, 1);
                                    } else {
                                        selectedValues.push(value);
                                    }

                                    setDepositTypes(selectedValues);
                                    setDisableSaveButton(false);
                                }}
                            />
                        </Col>



                    </Row>

                </Form.Group>
                <Form.Group controlId='requiredAt'>
                    <Form.Label>Required At ($)</Form.Label>
                    <Form.Control
                        type='number'
                        disabled={!isRequiredDeposit}
                        placeholder='Enter Required At'
                        value={requiredAt}
                        onChange={(e) => {
                            setRequiredAt(e.target.value);
                            setDisableSaveButton(false);
                        }}
                        required
                    />
                </Form.Group>
                <Form.Group controlId='depositRate'>
                    <Form.Label>Deposit Rate (%)</Form.Label>
                    <Form.Control
                        as='select'
                        value={depositRate}
                        disabled={!isRequiredDeposit}
                        onChange={(e) => {
                            setDepositRate(e.target.value);
                            setDisableSaveButton(false);
                        }}
                        required
                    >
                        {[...Array(11)].map((_, i) => (
                            <option key={i} value={i * 5}>
                                {i * 5}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId='stripeSecretKey'>
                    <Form.Label>Stripe Secret Key</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter your Stripe Secret Key'
                        value={stripeSecretKey}
                        disabled={!isRequiredDeposit}
                        onChange={(e) => {
                            setStripeSecretKey(e.target.value);
                            setDisableSaveButton(false);
                        }}
                        required
                    />
                </Form.Group>
                <Form.Group controlId='stripeWebhookSecret'>
                    <Form.Label>Stripe Webhook Secret Signature</Form.Label>
                    <Form.Control
                        type='text'
                        disabled={!isRequiredDeposit}
                        placeholder='Enter your Stripe Webhook Secret Signature'
                        value={stripeWebhookSecret}
                        onChange={(e) => {
                            setStripeWebhookSecret(e.target.value);
                            setDisableSaveButton(false);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Stripe Webhook URL</Form.Label>
                    <Form.Control
                        type='text'
                        readOnly
                        placeholder='Here is Stripe Webhook URL'
                        value={
                            "https://apiv2.dashbooking.com/api/appointment-deposit/stripe-listener"
                        }
                    />
                    <p className='text-warning'>
                        Add Event: payment_intent.succeeded for Webhook
                    </p>
                </Form.Group>
                <Button
                    disabled={disableSaveButton}
                    variant='primary'
                    type='submit'
                >
                    Save
                </Button>
                { }
            </Form>
        </div>
    );
};

export default OnlineDepositSettings;
