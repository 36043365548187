import React, { useState } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import API from '../../../utils/API/API';
const CopyMenu = (props) => {
    const { business } = props;
    const [url, setUrl] = useState('');
    const [menu, setMenu] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const fetchMenu = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await API.get(`/api/businesses/get-menu-from-url?urlName=${encodeURIComponent(url)}&businessId=${business._id}`);
            const data = response.data;

            setMenu(data);
        } catch (err) {
            if (err.response && err.response.status === 404) {

                setError(err.response?.data?.message || 'Unknown Error');
            } else {
                setError(err.message);
            }

        } finally {
            setLoading(false);
        }
    };

    const handleCopyMenu = async () => {
        setLoading(true);
        setError(null);
        setMessage(null);
        try {
            if (menu) {
                const response = await API.post("/api/businesses/menu/copy-menu", { urlName: url, businessId: business._id });
                const data = response.data;
                setMessage(data.message || 'Menu copied successfully');
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setError(err.response?.data?.message || 'Unknown Error');
            } else {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>Copy Menu from Other Business</h2>
            <Form >
                <p>Enter the business URL to fetch the menu</p>
                <p className="mt-3 text-warning">For new businesses, the menu should initially be empty. We recommend copying the menu first and then setting up staff details afterward.</p>
                <Form.Group controlId="formBasicUrl">
                    <Form.Label>Business URL ( e.g., creative-nails-and-spa)</Form.Label>
                    <Form.Control
                        type="text"
                        value={url}
                        onChange={handleUrlChange}
                        placeholder="Enter business URL only"
                        required={true}
                    />
                </Form.Group>
                <Button variant="primary" onClick={fetchMenu} disabled={loading || !url}>
                    {loading ? 'Fetching...' : 'Fetch Menu'}
                </Button>
            </Form>

            {menu && (
                <div className="mt-3">
                    <h3>Menu: <strong>{menu.categories.length}</strong> categories and <strong>{menu.categories.reduce((total, category) => total + category.services.length, 0)}</strong> services </h3>

                    <Row>
                        {menu.categories.map((category, index) => (
                            <Col key={index} md={4}>
                                <div >{category.name}</div>
                                <ul>
                                    {category.services.map((service, serviceIndex) => (
                                        <li key={serviceIndex}>{service.name}</li>
                                    ))}
                                </ul>
                            </Col>
                        ))}
                    </Row>
                    <p className="mt-3 text-warning">Only regular services can be copied. <strong className="text-danger">Add-on services and combos</strong> need to be set up manually.</p>

                    <Button disabled={loading} variant="primary" onClick={() => handleCopyMenu()} > {loading ? 'Copying...' : 'Copy Menu'}</Button>
                </div>
            )}

            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
    );
};

export default CopyMenu;