import {
    GET_DEPOSIT_SETTINGS_STARTED,
    GET_DEPOSIT_SETTINGS_SUCCESS,
    GET_DEPOSIT_SETTINGS_FAILURE,
    UPDATE_DEPOSIT_SETTINGS_STARTED,
    UPDATE_DEPOSIT_SETTINGS_SUCCESS,
    UPDATE_DEPOSIT_SETTINGS_FAILURE,
    REMOVE_ERROR_MESSAGE

} from '../../constants/deposit-settings/depositSettings'

const initialState = {
    settings: null,
    loading: false,
    message: null,
    error: null,
};

export default function depositSettings(state = initialState, action) {
    switch (action.type) {
        case GET_DEPOSIT_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case GET_DEPOSIT_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.settings,
                loading: false,
                message: null,
                error: null,
            };
        case GET_DEPOSIT_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };
        case UPDATE_DEPOSIT_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case UPDATE_DEPOSIT_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.settings,
                loading: false,
                message: "Stripe key has been saved!",
                error: null,
            };
        case UPDATE_DEPOSIT_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                message: null,
                error: action.payload.error,
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                message: null,
                error: null,
            };
        default:
            return state;
    }
}